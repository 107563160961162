<template>
  <tr>
    <wi-list-data-table-actions
      :props="props"
      :config="config"
    ></wi-list-data-table-actions>
    <td class="justify-left">
      <span v-if="props.item.device_child && props.item.device_child.device_direct && props.item.device_child.device_direct.network_names">
        {{ props.item.device_child.device_direct.network_names }}
      </span>
      <br>
      <strong>
        {{ props.item.device_child.device_direct.name }}
      </strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.device_child.parent" class="primary--text">
        <span>
          {{ props.item.device_child.parent.type.name }}
        </span>  
        <span>
          {{ props.item.device_child.parent.name }}
        </span>
        <span> / </span>
      </span> 
      <strong>
        {{ props.item.device_child.type.name }}
      </strong> 
      <strong>
        {{ props.item.device_child.name }}
      </strong>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'NetworkDevices',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        }
      }
    },
    methods: {
      addDeviceChilds: function () {
        this.$WiEditDialog({
          wiConfig: 'ticket-device-child',
          data: {
            device_id: this.props.item.device.id,
            ticket_id: this.$route.params.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>